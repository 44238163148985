import FundUnicefTemplate from '@/page-blocks/about-us/clear/fund/unicef/FundUnicefTemplate';
import ReportUnicef2016 from '@/page-blocks/about-us/clear/fund/unicef/report.unicef_2016';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicef2016: React.FC<PageProps> = (props) => (
  <FundUnicefTemplate year={2016} {...props}>
    <ReportUnicef2016 />
  </FundUnicefTemplate>
);

export default FundUnicef2016;
